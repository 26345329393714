<template>
  <div class="RealProject">
    <h2>实名制总览</h2>
    
    <div class="searchWrap" v-if="projectDetail">
      <el-descriptions title="项目基本信息" :column="3">
          <el-descriptions-item label="项目名称">{{projectDetail.projectName}}</el-descriptions-item>
          <el-descriptions-item label="施工许可证号">{{projectDetail.builderLicenseNumber}}</el-descriptions-item>
          <el-descriptions-item label="项目状态">{{projectDetail.projectStatusStr}}</el-descriptions-item>
          <el-descriptions-item label="项目分类">{{projectDetail.categoryStr}}</el-descriptions-item>
          <el-descriptions-item label="项目子类别">{{projectDetail.categorySub}}</el-descriptions-item>
          <el-descriptions-item label="项目所在地">{{projectDetail.areaAddress}}</el-descriptions-item>
          <el-descriptions-item label="项目地址">{{projectDetail.address}}</el-descriptions-item>
          <el-descriptions-item label="总投资(万元)">{{projectDetail.invest}}</el-descriptions-item>
          <el-descriptions-item label="总面积(平方米)">{{projectDetail.buildingArea}}</el-descriptions-item>
          <el-descriptions-item label="总长度(米)">{{projectDetail.buildingLength}}</el-descriptions-item>
          <el-descriptions-item label="开工日期">{{projectDetail.startDate}}</el-descriptions-item>
          <el-descriptions-item label="竣工日期">{{projectDetail.completeDate}}</el-descriptions-item>
          <el-descriptions-item label="项目简介">{{projectDetail.description}}</el-descriptions-item>
          <el-descriptions-item label="立项文号">{{projectDetail.approvalNumber}}</el-descriptions-item>
          <el-descriptions-item label="甲方代表">{{projectDetail.firstParty}}</el-descriptions-item>
          <el-descriptions-item label="甲方代表电话">{{projectDetail.firstPartyPhone}}</el-descriptions-item>
      </el-descriptions>
    </div>
    
    <el-table :data="listData" style="margin-top: 20px">
      <el-table-column type="index" width="50"> </el-table-column>
      <el-table-column prop="corpNmae" label="企业名称"> </el-table-column>
      <el-table-column prop="corpCode" label="统一社会信用代码"> </el-table-column>
      <el-table-column prop="legalMan" label="法定代表人姓名"> </el-table-column>
      <el-table-column prop="teamNum" label="班组数"> </el-table-column>
      <el-table-column prop="personnelNum" label="在场人数"> </el-table-column>
      <el-table-column prop="entryTime" label="进场日期"> </el-table-column>
      <el-table-column prop="exitTime" label="退场日期"> </el-table-column>
      <el-table-column prop="corpType" label="企业类型"> </el-table-column>
    </el-table>
    <el-pagination
      layout="total, sizes, prev, pager, next"
      :total="totals"
      :page-size="pageSize"
      :current-page.sync="pageNum"
      @current-change="onGetListData"
      @size-change="handleSizeChange"
      :page-sizes="[10, 50, 100, 200, 500]"
    >
    </el-pagination>
  </div>
</template>
    
<style lang="less" scoped>
.RealProject {
  padding: 20px;
}
</style>
    
<script>
export default {
  name: "RealProject",
  components: {},
  data() {
    return {
      pageSize: 10,
      pageNum: 1,
      totals: 0,
      listData: [],
      projectDetail: null
    };
  },
  computed: {},
  mounted() {
    this.onGetProjectDetail();
    this.onGetListData()
  },
  methods: {
    goto: function (path) {
      this.$router.push({
        name: path,
      });
    },
    // 获取项目详情
    onGetProjectDetail: function () {
      var that = this;
      var data = {
        projectId: localStorage.getItem('projectId'),
        displayUnit: false
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-workers/project/details",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            that.projectDetail = res.data;
          } else {
            that.$message(res.msg)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 获取主列表数据
    onGetListData: function () {
      var that = this;
      var data = {
        projectId: localStorage.getItem('projectId'),
        pageSize: this.pageSize,
        pageNum: this.pageNum,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-workers/realProjectSubContractor/list",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            this.pageSize = res.data.pageSize
            this.pageNum = res.data.pageNum
            this.totals = res.data.totals
            this.listData = res.data.dataList
          } else {
            that.$message(res.msg)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 切换分页大小
    handleSizeChange(val) {
      this.pageSize = val;
      this.pageNum = 1;
      this.onGetListData();
    },

  },
};
</script>
    